<template>
  <div class="filter-wrapper">
    <client-only>
      <b-collapse
        v-if="!badgesOnly"
        :visible="state"
        @shown="onShown"
        @hidden="onHidden"
      >
        <b-card
          ref="filter"
          class="filter__container mb-2"
        >
          <div
            :class="isMobile ? '' : ''"
            :style="isMobile ? `height: ${bodyScrollHeight}; overflow: hidden; overflow-y: auto; ` : ''"
          >
            <b-row
              class="w-100 m-0 p-0"
            >
              <b-col
                v-for="field in fields.filter(i => !i.noCollapse)"
                :key="field.key"
                :lg="field && field.size || 3"
                :md="6"
                :cols="12"
                :class="field && field.class || ''"
                class=""
              >
                <b-form-group
                  :id="field.key"
                  :label="field.label"
                  :label-for="`filter-input-${field.key}`"
                  label-size="sm"
                >
                  <b-form-select
                    v-if="isSelectField(field)"
                    :id="`filter-input-${field.key}`"
                    v-model="rawFilter[field.key]"
                    :options="filterData[field.key]"
                    label-field="value"
                    value-field="id"
                    size="sm"
                    :class="getInputCls(field.key)"
                  />
                  <b-form-datepicker
                    v-else-if="isDateField(field)"
                    :id="`filter-input-${field.key}`"
                    v-model="rawFilter[field.key]"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    size="sm"
                    locale="en-GB"
                    :class="getInputCls(field.key)"
                  />
                  <date-range
                    v-else-if="isDateRangeField(field)"
                    :id="`filter-input-${field.key}`"
                    v-model="rawFilter[`${field.key}`]"
                    :variant="getInputVariant(field.key)"
                  />
                  <num-range
                    v-else-if="isNumRangeField(field)"
                    :id="`filter-input-${field.key}`"
                    v-model="rawFilter[`${field.key}`]"
                    :variant="getInputVariant(field.key)"
                  />
                  <b-form-checkbox
                    v-else-if="isCheckBoxField(field)"
                    :id="`filter-checkbox-${field.key}`"
                    v-model="rawFilter[field.key]"
                    :class="getInputCls(field.key)"
                  />
                  <b-form-input
                    v-else
                    :id="`filter-input-${field.key}`"
                    v-model="rawFilter[field.key]"
                    trim
                    size="sm"
                    :class="getInputCls(field.key)"
                    @keyup.enter="() => { onSubmit(); }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <template #header>
            <div class="d-inline-flex align-items-center justify-content-between w-100">
              <div>
                <font-awesome-icon
                  :icon="['fas', 'filter']"
                  class="mr-1"
                />
                {{ $t('eDoc_form_a_filter') }}
              </div>
              <b-link
                class="text-secondary"
                @click="() => { toggleState(); }"
              >
                <font-awesome-icon
                  :icon="['fas', 'xmark']"
                />
              </b-link>
            </div>
          </template>
          <template #footer>
            <b-row ref="footer">
              <b-col class="d-inline-flex justify-content-end">
                <!--
                <b-btn variant="outline-secondary" size="sm" @click="() => { toggleState(); }">
                  {{ $t('eDt_btnClose') }}
                </b-btn>
                -->
                <b-btn class="ml-2" size="sm" @click="() => { onClear(); toggleState(); }">
                  {{ $t('eDt_btnClear') }}
                </b-btn>
                <b-btn variant="primary" class="ml-2" size="sm" @click="() => { onSubmit(); }">
                  {{ $t('eDt_btnSubmit') }}
                </b-btn>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-collapse>
      <div
        v-if="showBadges"
        class="d-flex mb-2 align-items-start justify-content-start flex-nowrap"
      >
        <!--
        <b-button
          class="font-weight-bold mr-2"
          variant="outline-warning"
          size="sm"
          @click="() => { toggleState(); }"
        >
          <font-awesome-icon
            :icon="['fas', 'filter']"
            size="xs"
          />
        </b-button>
        -->
        <b-row class="w-100">
          <b-col cols="12" class="text-wrap">
            <b-badge
              v-for="field in filter.filter(i => !i.permanent && !isNoCollapseField(i.column) && i.value !== '')"
              :key="field.column"
              class="mr-1"
              variant="secondary"
            >
              {{ getItemLabel(field) }}: {{ getItemValue(field) }}
              <b-link
                class="ml-1 text-white"
                @click="() => { onClearItem(field); }"
              >
                <font-awesome-icon
                  :icon="['fas', 'xmark']"
                />
              </b-link>
            </b-badge>
          </b-col>
        </b-row>
      </div>
    </client-only>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import fields from '~/mixins/fields'

export default {
  name: 'DataFilter',
  props: {
    value: [Boolean],
    fields: {
      type: Array,
      default: () => ([])
    },
    filter: {
      type: Array,
      default: () => ([])
    },
    filterData: {
      type: Object,
      default: () => ({})
    },
    badgesOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: this.value,
      rawFilter: {},
      bodyScrollHeight: '500px',
      badges: []
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    dataFields () {
      return this.fields && this.fields.filter((field) => {
        return field && field.filter
      })
    },
    isMobile () {
      return this.deviceInfo?.type === 'mobile'
    },
    noCollapseCount () {
      return this.fields?.filter(i => !!i.noCollapse).length
    },
    showBadges () {
      if (this.isMobile) {
        return !this.state && (this.filter.filter(i => !i.permanent).length > 0) //
      } else {
        return (this.filter.filter(i => !i.permanent).length > 0)
      }
    }
    // hash () {
    //   const data = JSON.stringify(this.filterData) + JSON.stringify(this.filter)
    //   return crypto.createHash('md5').update(data).digest('hex')
    // }
  },
  watch: {
    value (n) {
      this.state = !!n
      this.$nextTick(() => {
        this.calcHeight() //
      })
    },
    filter: {
      handler (n) {
        this.setRawFilter()
        this.$nextTick(() => {
          this.$forceUpdate()
        })
      },
      deep: true,
      immediate: true
    }
    // hash () {
    //   const fields = this.filter.filter(i => !i.permanent && !this.isNoCollapseField(i.column) && i.value !== '')
    //   // this.badges = []
    //   for (let i = 0; i < fields.length; i++) {
    //     const field = fields[i]
    //     console.log('COL', JSON.stringify(field), this.filterData[field.column])
    //   }
    // }
  },
  mounted () {
    this.setRawFilter()
    this.calcHeight()
  },
  methods: {
    ...fields.methods,
    toggleState () {
      this.state = !this.state
      this.$emit('input', this.state)
    },
    setRawFilter () {
      this.filter.forEach((item) => {
        this.rawFilter[item.column] = item.value
      })
    },
    getItemLabel (item) {
      let label = ''
      if (item && item.column) {
        label = item.column
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          label = field.label
        }
      }
      return label
    },
    getItemValue (item) {
      let value = item.value
      if (item && item.column) {
        const field = this.fields.find(field => field.key === item.column)
        if (field) {
          if (this.isSelectField(field)) {
            const option = this.filterData[item.column].find(option => parseInt(option.id, 10) === parseInt(item.value, 10))
            if (option) {
              value = option.text
            }
          } else if (this.isAutocompleteField(field)) {
            const options = this.filterData[item.column]
            if (options?.length) {
              const option = options.find(option => parseInt(option.id, 10) === parseInt(item.value, 10))
              if (option) {
                value = option.text
              }
            }
          } else if (this.isDateRangeField(field)) {
            const keys = Object.keys(item.value)
            if (keys.length === 2) {
              value = this.$t('eDt_f_dateRange_between', item.value)
            } else if (keys.includes('from')) {
              value = this.$t('eDt_f_dateRange_after', item.value)
            } else if (keys.includes('to')) {
              value = this.$t('eDt_f_dateRange_before', item.value)
            }
          } else if (this.isNumRangeField(field)) {
            const keys = Object.keys(item.value)
            if (keys.length === 2) {
              value = this.$t('eDt_f_numRange_between', item.value)
            } else if (keys.includes('from')) {
              value = this.$t('eDt_f_numRange_after', item.value)
            } else if (keys.includes('to')) {
              value = this.$t('eDt_f_numRange_before', item.value)
            }
          }
        }
      }
      return value
    },
    onClearItem (item) {
      const filter = {}
      Object.keys(this.rawFilter)
        .filter(fieldName => fieldName !== item.column)
        .forEach((fieldName) => {
          filter[fieldName] = this.rawFilter[fieldName]
        })
      this.rawFilter = filter
      this.onSubmit()
    },
    onClear () {
      Object.keys(this.rawFilter).forEach((fieldKey) => {
        const initial = this.filter.find(i => i.column === fieldKey)
        if (!(initial && initial.permanent)) {
          this.rawFilter[fieldKey] = null
        }
      })
      this.$emit('submit', this.filter.filter(i => i.permanent))
    },
    onSubmit () {
      const data = []
      Object.keys(this.rawFilter)
        .filter((fieldKey) => {
          return !!this.rawFilter[fieldKey]
        })
        .forEach((fieldKey) => {
          const initial = this.filter.find(i => i.column === fieldKey)
          data.push({
            column: fieldKey,
            value: this.rawFilter[fieldKey],
            permanent: !!(initial && initial.permanent)
          })
        })
      this.$emit('submit', data)
    },
    onShown (e) {
      this.$emit('on-shown', e)
    },
    onHidden (e) {
      this.$emit('on-hidden', e)
    },
    calcHeight () {
      this.bodyScrollHeight = '0'
      if (this.$refs.filter && window) {
        const $navbar = document.querySelector('.navbar')
        const $filter = this.$refs?.filter
        const windowHeight = window.innerHeight
        const navbarHeight = $navbar?.clientHeight || 0
        const topBarHeight = this.$parent?.$refs?.topBar?.offsetHeight || 0
        const cardHeaderHeight = $filter?.querySelector('.card-header')?.offsetHeight || 0
        const cardFooterHeight = $filter?.querySelector('.card-footer')?.offsetHeight || 0
        const cardBodyGaps = 16

        const height = windowHeight - navbarHeight - topBarHeight - cardHeaderHeight - cardFooterHeight - cardBodyGaps - 16
        this.bodyScrollHeight = `${height}px`

        // settings hardcode
        if (this.$route.path.match(/settings/)) {
          this.bodyScrollHeight = '260px'
        }
      }
    },
    getInputCls (key) {
      if (Object.keys(this.rawFilter).includes(key) && this.rawFilter[key] !== '') {
        return 'border-info text-info'
      }
      return 'border-muted text-muted'
    },
    getInputVariant (key) {
      if (Object.keys(this.rawFilter).includes(key)) {
        return 'info'
      }
      return 'muted'
    },
    isNoCollapseField (key) {
      return this.fields
        .filter(i => i.noCollapse)
        .map(i => i.key)
        .includes(key)
    }
  }
}
</script>

<style lang="scss">
.filter-wrapper {
  .b-form-datepicker {
    &.text-info {
      .btn, .form-control  {
        color: var(--info) !important;
      }
    }
  }
}
</style>
